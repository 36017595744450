import api from '@/axios.js';

const state = {
  baseUrlPath: '/api/report/v1',
  tableData: [],
  total: 0,
  totalPerPage: 10,
  totalPage: 1,
  totalSearch: 10,
};

const mutations = {
  MAP_DATA(state, val) {
    state.tableData = val.data.records
    state.total = val.data.total_record;
    state.totalPerPage = val.data.total_record_per_page;
    state.totalPage = val.data.total_page;
    state.totalSearch = val.data.total_record_search;
  },
};

const actions = {
  getSODOGIBILLReport({ state, commit }, payload) {
    api.get(`${state.baseUrlPath}/report/sodogibill-export`, { params: payload }).then(
      (result) => {
        commit('MAP_DATA', result);
        return result;
      },
      (error) => {
        return error;
      }
    );
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
