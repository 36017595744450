import api from '@/axios.js';
import moment from 'moment';

const state = {
  baseUrlPath: '/api/report/v1/report/ap-aging-export',
  tableData: [],
  total: 0,
  totalPerPage: 10,
  totalPage: 1,
  totalSearch: 10,
};

const mutations = {
  UPDATE_TABLE_DATA(state, val) {
    state.tableData = val.data.records.map((x) => {
      return {
        ...x,
        invoice_date:
          x.invoice_date == null
            ? ''
            : moment(x.invoice_date).format('DD/MM/YYYY'),
        invoice_due_date:
          x.invoice_due_date == null
            ? ''
            : moment(x.invoice_due_date).format('DD/MM/YYYY'),
        vendor_bill_date:
          x.vendor_bill_date == null
            ? ''
            : moment(x.vendor_bill_date).format('DD/MM/YYYY'),
        posting_date:
          x.posting_date == null
            ? ''
            : moment(x.posting_date).format('DD/MM/YYYY'),
        payment_date:
          x.payment_date == null
            ? ''
            : moment(x.payment_date).format('DD/MM/YYYY'),
        payment_posting_date:
          x.payment_posting_date == null
            ? ''
            : moment(x.payment_posting_date).format('DD/MM/YYYY'),
        created_by:
          x.created_by == null
            ? ''
            : moment(x.created_by).format('DD/MM/YYYY HH:mm:ss'),
        updated_by:
          x.updated_by == null
            ? ''
            : moment(x.updated_by).format('DD/MM/YYYY HH:mm:ss'),
      };
    });
    state.total = val.data.total_record;
    state.totalPerPage = val.data.total_record_per_page;
    state.totalPage = val.data.total_page;
    state.totalSearch = val.data.total_record_search;
  },
};

const actions = {
  getAPAgingReport({ state, commit }, payload) {
    api.get(state.baseUrlPath, { params: payload }).then(
      (result) => {
        commit('UPDATE_TABLE_DATA', result);
        return result;
      },
      (error) => {
        return false;
      }
    );
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
