import api from '@/axios.js';

const state = {
  baseUrlPath: '/api/report/v1',
  dataList: [],
  total: 0,
  totalPerPage: 10,
  totalPage: 1,
  totalSearch: 10,
  ouList:[],
  storageAreaList:[],
  principalList:[],
  skuCodeList:[],
  batchNumberList:[],
  groupEdList:[],
  bucketAgingList:[],
};

const mutations = {
  MAP_DATA(state, val) {
    console.log(val.data.total_page)
    state.dataList = val.data.records;
    state.total = val.data.total_record;
    state.totalPerPage = val.data.total_record_per_page;
    state.totalPage = val.data.total_page;
    state.totalSearch = val.data.total_record_search;
  },
  MAP_OU(state, val) {
    state.ouList = val.data.records;
  },
  MAP_STORAGE_AREA(state, val) {
    state.storageAreaList = val.data.records;
  },
  MAP_PRINCIPAL(state, val) {
    state.principalList = val.data.records;
  },
  MAP_SKU_CODE(state, val) {
    state.skuCodeList = val.data.records;
  },
  MAP_BATCH_NUMBER(state, val) {
    state.batchNumberList = val.data.records;
  },
  MAP_GROUP_ED(state, val) {
    state.groupEdList = val.data.records;
  },
  MAP_BUCKET_AGING(state, val) {
    state.bucketAgingList = val.data.records;
  },
};

const actions = {
  getExpiredReport({ state, commit }, payload) {
    api.get(`${state.baseUrlPath}/report/expired-report`, { params: payload }).then(
      (result) => {
        commit('MAP_DATA', result);
        return result;
      },
      () => {
        return false;
      }
    );
  },
  getOu({ state, commit }, payload) {
    api.get(`${state.baseUrlPath}/master/ou`, { params: payload }).then(
      (result) => {
        commit('MAP_OU', result);
        return result;
      },
      () => {
        return false;
      }
    );
  },
  getStorageArea({ state, commit }, payload) {
    api.get(`${state.baseUrlPath}/master/storage-area`, { params: payload }).then(
      (result) => {
        commit('MAP_STORAGE_AREA', result);
        return result;
      },
      () => {
        return false;
      }
    );
  },
  getPrincipal({ state, commit }, payload) {
    api.get(`${state.baseUrlPath}/master/principal`, { params: payload }).then(
      (result) => {
        commit('MAP_PRINCIPAL', result);
        return result;
      },
      () => {
        return false;
      }
    );
  },
  getSkuCode({ state, commit }, payload) {
    api.get(`${state.baseUrlPath}/master/sku-code`, { params: payload }).then(
      (result) => {
        commit('MAP_SKU_CODE', result);
        return result;
      },
      () => {
        return false;
      }
    );
  },
  getBatchNumber({ state, commit }, payload) {
    api.get(`${state.baseUrlPath}/master/batch-number`, { params: payload }).then(
      (result) => {
        commit('MAP_BATCH_NUMBER', result);
        return result;
      },
      () => {
        return false;
      }
    );
  },
  getGroupEd({ state, commit }, payload) {
    api.get(`${state.baseUrlPath}/master/group-ed`, { params: payload }).then(
      (result) => {
        commit('MAP_GROUP_ED', result);
        return result;
      },
      () => {
        return false;
      }
    );
  },
  getBucketAging({ state, commit }, payload) {
    api.get(`${state.baseUrlPath}/master/bucket-aging`, { params: payload }).then(
      (result) => {
        commit('MAP_BUCKET_AGING', result);
        return result;
      },
      () => {
        return false;
      }
    );
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
