import api from '@/axios.js';

const state = {
    baseUrlPath: '/api/report/v1',
    tableData: [],
    total: 0,
    totalPerPage: 10,
    totalPage: 1,
    totalSearch: 10,
    vendorList: [],
    statusList: [],
};

const mutations = {
    MAP_DATA(state, val) {
        state.tableData = val.data.records
        state.total = val.data.total_record;
        state.totalPerPage = val.data.total_record_per_page;
        state.totalPage = val.data.total_page;
        state.totalSearch = val.data.total_record_search;
    },
    MAP_VENDOR(state, val) {
        state.vendorList = val.data.record
    },
    MAP_STATUS(state, val) {
        state.statusList = val.data.record
    },
};

const actions = {
    getItemSpecialReport({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/report/fbl1n-open-item-special-gl`, { params: payload })
            .then(
                (result) => {
                    commit('MAP_DATA', result);
                    return result;
                })
            .catch((error) => {
                return error;
            });
    },
    getVendor({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/master/vendor`, { params: payload })
            .then(
                (result) => {
                    commit('MAP_VENDOR', result);
                    return result;
                })
            .catch((error) => {
                return error;
            });
    },
    getStatus({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/master/doc-status`, { params: payload })
            .then(
                (result) => {
                    commit('MAP_STATUS', result);
                    return result;
                })
            .catch((error) => {
                return error;
            });
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
