import api from '@/axios.js';

const state = {
    baseUrlPath: '/api/report/v1',
    tableData: [],
    total: 0,
    totalPerPage: 10,
    totalPage: 1,
    totalSearch: 10,
    storageList: [],
    skuCodeList: [],
    batchList: [],
    agingGroupList:[],
};

const mutations = {
    MAP_DATA(state, val) {
        state.tableData = val.data.records
        state.total = val.data.total_record;
        state.totalPerPage = val.data.total_record_per_page;
        state.totalPage = val.data.total_page;
        state.totalSearch = val.data.total_record_search;
    },
    MAP_STORAGE(state, val) {
        state.storageList = val.data.records
    },
    MAP_SKU_CODE(state, val) {
        state.skuCodeList = val.data.records
    },
    MAP_BATCH(state, val) {
        state.batchList = val.data.records
    },
    MAP_AGING_GROUP(state, val) {
        state.agingGroupList = val.data.records
    },
};

const actions = {
    getAgingPropductReport({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/report/sdn-report-list-aging-product-part-b`, { params: payload })
            .then(
                (result) => {
                    commit('MAP_DATA', result);
                    return result;
                })
            .catch((error) => {
                return error;
            });
    },
    getStorage({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/master/storage`, { params: payload })
            .then(
                (result) => {
                    commit('MAP_STORAGE', result);
                    return result;
                })
            .catch((error) => {
                return error;
            });
    },
    getSkuCode({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/master/sku-code`, { params: payload })
            .then(
                (result) => {
                    commit('MAP_SKU_CODE', result);
                    return result;
                })
            .catch((error) => {
                return error;
            });
    },
    getBatch({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/master/batch-number`, { params: payload })
            .then(
                (result) => {
                    commit('MAP_BATCH', result);
                    return result;
                })
            .catch((error) => {
                return error;
            });
    },
    getAgingGroup({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/master/grouping-aging`, { params: payload })
            .then(
                (result) => {
                    commit('MAP_AGING_GROUP', result);
                    return result;
                })
            .catch((error) => {
                return error;
            });
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
