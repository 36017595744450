import api from '@/axios.js';
import moment from 'moment';

const state = {
  baseUrlPath: '/api/report/v1/report/po-export',
  tableData: [],
  total: 0,
  totalPerPage: 10,
  totalPage: 1,
  totalSearch: 10,
};

const mutations = {
  UPDATE_TABLE_DATA(state, val) {
    state.tableData = val.data.records.map((x) => {
      return {
        ...x,
        doc_date:
          x.doc_date == null ? '' : moment(x.doc_date).format('DD/MM/YYYY'),
        gr_ir_date:
          x.gr_ir_date == null ? '' : moment(x.gr_ir_date).format('DD/MM/YYYY'),
        rdd: x.rdd == null ? '' : moment(x.rdd).format('DD/MM/YYYY'),
        posting_date:
          x.posting_date == null
            ? ''
            : moment(x.posting_date).format('DD/MM/YYYY'),
      };
    });
    state.total = val.data.total_record;
    state.totalPerPage = val.data.total_record_per_page;
    state.totalPage = val.data.total_page;
    state.totalSearch = val.data.total_record_search;
  },
};

const actions = {
  getPOReport({ state, commit }, payload) {
    api.get(state.baseUrlPath, { params: payload }).then(
      (result) => {
        commit('UPDATE_TABLE_DATA', result);
        return result;
      },
      (error) => {
        return false;
      }
    );
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
