import api from '@/axios.js';
import moment from 'moment';

const state = {
  baseUrlPath: '/api/report/v1/report/stmovement-export',
  tableData: [],
  total: 0,
  totalPerPage: 10,
  totalPage: 1,
  totalSearch: 10,
};

const mutations = {
  UPDATE_TABLE_DATA(state, val) {
    state.tableData = val.data.records.map((x) => {
      return {
        ...x,
        posting_date:
          x.posting_date == null
            ? ''
            : moment(x.posting_date).format('DD/MM/YYYY'),
        document_date:
          x.document_date == null
            ? ''
            : moment(x.document_date).format('DD/MM/YYYY'),
        best_before_date:
          x.best_before_date == null
            ? ''
            : moment(x.best_before_date).format('DD/MM/YYYY'),
        trans_date:
          x.trans_date == null
            ? ''
            : moment(x.trans_date).format('DD/MM/YYYY HH:mm:ss'),
      };
    });
    state.total = val.data.total_record;
    state.totalPerPage = val.data.total_record_per_page;
    state.totalPage = val.data.total_page;
    state.totalSearch = val.data.total_record_search;
  },
};

const actions = {
  getSTMovementReport({ state, commit }, payload) {
    api.get(state.baseUrlPath, { params: payload }).then(
      (result) => {
        commit('UPDATE_TABLE_DATA', result);
        return result;
      },
      (error) => {
        return false;
      }
    );
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
