import api from '@/axios.js';

const state = {
    baseUrlPath: '/api/report/v1',
    data: null,
}

const mutations = {
    MAP_DATA: (state, val) => {
        state.data = val.data.Records
    },
}

const actions = {
    getSopipodbill({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/dashboard/sopipodbill`, { params: payload }).then(
            (result) => {
                commit('MAP_DATA', result);
                return result;
            },
            () => {
                return false;
            }
        );
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
