import api from '@/axios.js';

const state = {
    baseUrlPath: '/api/report/v1/report/journal-monitoring',
    tableData: [],
    total: 0,
    totalPerPage: 10,
    totalPage: 1,
    totalSearch: 10,
    oUList: []
};

const mutations = {
    MAP_DATA(state, val) {
        state.tableData = val.data.records
        state.total = val.data.total_record;
        state.totalPerPage = val.data.total_record_per_page;
        state.totalPage = val.data.total_page;
        state.totalSearch = val.data.total_record_search;
    },
    MAP_OU(state, val) {
        state.oUList = val.data.records
    },
};

const actions = {
    getJournalMonitoringReport({ state, commit }, payload) {
        api.get(state.baseUrlPath, { params: payload })
            .then(
                (result) => {
                    commit('MAP_DATA', result);
                    return result;
                })
            .catch((error) => {
                return error;
            });
    },
    getOu({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/master/ou`, { params: payload })
            .then(
                (result) => {
                    commit('MAP_OU', result);
                    return result;
                })
            .catch((error) => {
                return error;
            });
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
