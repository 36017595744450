import api from '@/axios.js';

const state = {
    baseUrlPath: '/api/report/v1',
    tableData: [],
    total: 0,
    totalPerPage: 10,
    totalPage: 1,
    totalSearch: 10,
    ouList:[],
    distributionChannelList:[],
    salesList:[],
    principalList:[],
};

const mutations = {
    MAP_DATA(state, val) {
        state.tableData = val.data.records
        state.total = val.data.total_record;
        state.totalPerPage = val.data.total_record_per_page;
        state.totalPage = val.data.total_page;
        state.totalSearch = val.data.total_record_search;
    },
    MAP_OU(state, val) {
        state.ouList = val.data.records
    },
    MAP_DISTRIBUTION_cHANNEL(state, val) {
        state.distributionChannelList = val.data.records
    },
    MAP_SALES(state, val) {
        state.salesList = val.data.records
    },
    MAP_PRINCIPAL(state, val) {
        state.principalList = val.data.records
    },
};

const actions = {
    getBillOrderReport({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/report/bill-order`, { params: payload })
            .then(
                (result) => {
                    commit('MAP_DATA', result);
                    return result;
                })
            .catch((error) => {
                return error;
            });
    },
    getOu({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/master/ou`, { params: payload })
            .then(
                (result) => {
                    commit('MAP_OU', result);
                    return result;
                })
            .catch((error) => {
                return error;
            });
    },
    getDistributionChannel({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/master/principal`, { params: payload })
            .then(
                (result) => {
                    commit('MAP_DISTRIBUTION_cHANNEL', result);
                    return result;
                })
            .catch((error) => {
                return error;
            });
    },
    getSales({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/master/principal`, { params: payload })
            .then(
                (result) => {
                    commit('MAP_SALES', result);
                    return result;
                })
            .catch((error) => {
                return error;
            });
    },
    getPrincipal({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/master/principal`, { params: payload })
            .then(
                (result) => {
                    commit('MAP_PRINCIPAL', result);
                    return result;
                })
            .catch((error) => {
                return error;
            });
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
