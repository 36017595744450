import api from '@/axios.js';

const state = {
    baseUrlPath: '/api/report/v1',
    tableData: [],
    total: 0,
    totalPerPage: 10,
    totalPage: 1,
    totalSearch: 10,
    shipmentNoList: [],
    piNoList: [],
    shipmentCostNoList: [],
    fwdAgentNoList: [],
};

const mutations = {
    MAP_DATA(state, val) {
        state.tableData = val.data.records
        state.total = val.data.total_record;
        state.totalPerPage = val.data.total_record_per_page;
        state.totalPage = val.data.total_page;
        state.totalSearch = val.data.total_record_search;
    },
    MAP_SHIPMENT(state, val) {
        state.shipmentNoList = val.data.records
    },
    MAP_PI(state, val) {
        state.piNoList = val.data.records
    },
    MAP_SHIPMENT_COST(state, val) {
        state.shipmentCostNoList = val.data.records
    },
    MAP_FWD_AGENT(state, val) {
        state.fwdAgentNoList = val.data.records
    },
};

const actions = {
    getShipmentPlanningReport({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/report/sdn-report-shipment-planning-2`, { params: payload })
            .then(
                (result) => {
                    commit('MAP_DATA', result);
                    return result;
                })
            .catch((error) => {
                return error;
            });
    },
    getShipmentNo({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/master/shipment-no`, { params: payload })
            .then(
                (result) => {
                    commit('MAP_SHIPMENT', result);
                    return result;
                })
            .catch((error) => {
                return error;
            });
    },
    getPiNo({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/master/pi-no`, { params: payload })
            .then(
                (result) => {
                    commit('MAP_PI', result);
                    return result;
                })
            .catch((error) => {
                return error;
            });
    },
    getShipmentCostNo({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/master/shipment-cost-no`, { params: payload })
            .then(
                (result) => {
                    commit('MAP_SHIPMENT_COST', result);
                    return result;
                })
            .catch((error) => {
                return error;
            });
    },
    getFwdAgentNo({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/master/fwd-agent-no`, { params: payload })
            .then(
                (result) => {
                    commit('MAP_FWD_AGENT', result);
                    return result;
                })
            .catch((error) => {
                return error;
            });
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
