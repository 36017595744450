
import api from '@/axios.js';

const state = {
    baseUrlPath: '/api/report/v1',
    dataList: [],
    total_page: 0,
    total_record: 0,
    total_record_per_page: 0,
    total_record_search: 0,
}

const mutations = {
    MAP_DATA: (state, val) => {
        state.dataList = val.data.records
        state.total_page = val.data.total_page
        state.total_record = val.data.total_record
        state.total_record_per_page = val.data.total_record_per_page
        state.total_record_search = val.data.total_record_search
    },
}

const actions = {
    getPurchaseOrderReport({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/report/purchase-order`, { params: payload }).then(
            (result) => {
                commit('MAP_DATA', result);
                return result;
            },
            () => {
                return false;
            }
        );
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
